import api from '@/api/index.js'
import instance from '@/api/instance.js'

export default {
  install: (app) => {
    app.config.globalProperties.$api = api

    instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token')
        if (token) {
          config.headers = {
            Authorization: `Bearer ${token}`,
          }
        }
        return config
      },
      (error) => {
        console.log(error.response)
      }
    )
    instance.interceptors.response.use(
      (config) => {
        return config
      },
      async (error) => {
        console.log(error.response)
      }
    )
  },
}
