export default {
  name: 'parallax',
  mounted(el) {
    let child
    try {
      child = el.children[0]
    } catch (e) {
      console.log(e)
    }
    const minSize = Math.min(el.clientWidth, el.clientHeight)
    const perspective = minSize * 2 + 'px'
    el.addEventListener('mousemove', (e) => {
      const elementCoords = el.getBoundingClientRect()
      const rotateX = (
        (e.clientX - elementCoords.left - el.clientWidth / 2) /
        64
      ).toFixed(2)
      const rotateY = (
        (e.clientY - elementCoords.top - el.clientHeight / 2) /
        64
      ).toFixed(2)
      if (child) {
        child.style.transition = 'transform 0s, box-shadow 0.3s'
        el.style.transition = 'transform 0s, box-shadow 0.3s'
        child.style.transform = `perspective(${perspective}) rotateY(${rotateX}deg) rotateX(${-rotateY}deg) scale3d(1, 1, 1)`
        child.style.willChange = 'transform'
      } else {
        el.style.transition = 'transform 0s, box-shadow 0.3s'
        el.style.transform = `perspective(${perspective}) rotateY(${rotateX}deg) rotateX(${-rotateY}deg) scale3d(1, 1, 1)`
        el.style.willChange = 'transform'
      }
    })
    el.addEventListener('mouseleave', () => {
      if (child) {
        child.style.transition = 'transform 0.3s, box-shadow 0.3s'
        child.style.transform = 'none'
      } else {
        el.style.transition = 'transform 0.3s, box-shadow 0.3s'
        el.style.transform = 'none'
      }
    })
  },
}
