import axios from 'axios'
import appConfig from '@/app.config.js'

const instance = axios.create({
  baseURL: appConfig.server.baseURL,
  headers: {
    accept: 'application/json',
  },
})

export default instance
