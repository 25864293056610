export default [
  {
    path: '',
    name: 'Catalog-main',
    component: () => import('@/views/Admin/Catalog/CatalogView.vue'),
    meta: { name: 'Каталог' },
  },
  {
    path: '/category/create',
    name: 'Category-create',
    component: () => import('@/views/Admin/Catalog/CreateCategory.vue'),
    meta: { name: 'Создать категорию' },
  },
  {
    path: '/category/update',
    name: 'Category-update',
    component: () => import('@/views/Admin/Catalog/UpdateCategory.vue'),
    meta: { name: 'Изменить категорию' },
  },
  {
    path: '/product/create',
    name: 'Product-create',
    component: () => import('@/views/Admin/Catalog/CreateProduct.vue'),
    meta: { name: 'Создать товар' },
  },
  {
    path: '/product/update',
    name: 'Product-update',
    component: () => import('@/views/Admin/Catalog/UpdateProduct.vue'),
    meta: { name: 'Изменить товар' },
  },
  {
    path: '/service/create',
    name: 'Service-create',
    component: () => import('@/views/Admin/Catalog/CreateService.vue'),
    meta: { name: 'Создать услугу' },
  },
  {
    path: '/service/update',
    name: 'Service-update',
    component: () => import('@/views/Admin/Catalog/UpdateService.vue'),
    meta: { name: 'Изменить услугу' },
  },
]
