export default function (instance) {
  return {
    login(payload) {
      return instance.post('/api/v1/cp/sign-in/', payload)
    },
    sendResetRequest(payload) {
      return instance.post('api/v1/cp/recover-password', payload)
    },
    getCurrentUser() {
      return instance.get('/api/v1/cp/moderators/me/')
    },
  }
}
