export default {
  DEBUG: true,
  server: {
    baseURL: 'http://87.249.49.97:5041',
  },
  password: {
    MIN_LENGTH: 5,
  },
  login: {
    MIN_LENGTH: 4,
    MAX_LENGTH: 16,
  },
  statusList: [
    { name: 'Новый', icon: require('@/assets/status/new.svg'), background: '#11bfff44' },
    {
      name: 'В работе',
      icon: require('@/assets/status/progress.svg'),
      background: '#FFDE5944',
    },
    {
      name: 'Выполнен',
      icon: require('@/assets/status/completed.svg'),
      background: '#88FF5944',
    },
    {
      name: 'Отменен',
      icon: require('@/assets/status/cancel.svg'),
      background: '#ff598044',
    },
  ],

  sidebarLinks: [
    {
      id: 1,
      name: 'AxasStore',
      routeName: 'Main',
      pathToIcon: require('@/assets/Pages/icons/logo.svg'),
      children: [],
    },
    {
      id: 2,
      name: 'Баннеры',
      routeName: 'Banners',
      pathToIcon: require('@/assets/Pages/icons/banners.svg'),
      children: [],
    },
    {
      id: 3,
      name: 'Заказы',
      routeName: 'Orders',
      pathToIcon: require('@/assets/Pages/icons/orders.png'),
      children: [],
    },
    {
      id: 4,
      name: 'Записи',
      routeName: 'Notes',
      pathToIcon: require('@/assets/Pages/icons/notes.png'),
      children: [],
    },
    {
      id: 5,
      name: 'Клиенты',
      routeName: 'Clients',
      pathToIcon: require('@/assets/Pages/icons/users.png'),
      children: [],
    },
    {
      id: 6,
      name: 'Каталог',
      routeName: 'Catalog-main',
      pathToIcon: require('@/assets/Pages/icons/catalog.png'),
      children: [],
    },
    {
      id: 7,
      name: 'Поддержка',
      routeName: 'Support',
      pathToIcon: require('@/assets/Pages/icons/support.png'),
      children: [],
    },
    {
      id: 8,
      name: 'Приложение',
      routeName: 'App',
      pathToIcon: require('@/assets/Pages/icons/phone.svg'),
      children: [],
    },
    {
      id: 9,
      name: 'Аналитика',
      routeName: 'Analytics',
      pathToIcon: require('@/assets/Pages/icons/analytics.png'),
      children: [],
    },
    {
      id: 10,
      name: 'Управление',
      routeName: 'Manage',
      pathToIcon: require('@/assets/Pages/icons/manage.png'),
      isOpen: false,
      children: [
        {
          id: 1,
          name: 'Города',
          routeName: 'Cities',
          pathToIcon: require('@/assets/Pages/icons/cities.png'),
        },
        {
          id: 2,
          name: 'Филиалы',
          routeName: 'Branches',
          pathToIcon: require('@/assets/Pages/icons/branches.png'),
        },
        {
          id: 3,
          name: 'Настройки',
          routeName: 'Settings',
          pathToIcon: require('@/assets/Pages/icons/settings.png'),
        },
      ],
    },
  ],
}
