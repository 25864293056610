export default {
  install: (app) => {
    app.config.globalProperties.$filter = {
      phone(phoneNumber) {
        return (
          '+' +
          phoneNumber[0] +
          '-' +
          phoneNumber.slice(1, 4) +
          '-' +
          phoneNumber.slice(4, 7) +
          '-' +
          phoneNumber.slice(7, 9) +
          '-' +
          phoneNumber.slice(9, 12)
        )
      },
      time(time) {
        let hours = Math.round(time / 100)
        if (hours === 0) {
          hours = '00'
        } else if (hours < 10) {
          hours = '0' + hours
        }
        let minutes = time % 10
        if (minutes === 0) {
          minutes = '00'
        } else {
          minutes += '0'
        }
        return `${hours}:${minutes}`
      },
    }
  },
}
