import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import createRouter from '@/router/index.js'
import plugins from '@/plugins/index.js'
import directives from '@/directives/index.js'

import VueTheMask from 'vue-the-mask'

const app = createApp(App)
const router = createRouter(store)
app.use(store).use(router)

directives.forEach((directive) => {
  app.directive(directive.name, directive)
})

plugins.forEach((plugin) => {
  app.use(plugin)
})
app.use(VueTheMask)

app.mount('#app')
