import { createRouter, createWebHistory } from 'vue-router'
import authorizationRouteList from '@/router/authorization.js'
import catalogRouteList from '@/router/catalog.js'

const routes = [
  {
    path: '/authorization',
    name: 'Authorization',
    redirect: { name: 'Login' },
    component: () => import('@/views/Authorization/AuthorizationLayout.vue'),
    meta: { name: 'Авторизация', auth: false, superUser: false },
    children: [...authorizationRouteList],
  },
  {
    path: '/',
    name: 'Admin',
    redirect: { name: 'Main' },
    component: () => import('@/views/Admin/AdminLayout.vue'),
    meta: { name: 'Админ панель', auth: true, superUser: false },
    children: [
      {
        path: '/main',
        name: 'Main',
        component: () => import('@/views/Admin/MainView.vue'),
        meta: { name: 'Панель управления' },
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/Admin/ProfileView.vue'),
        meta: { name: 'Профиль' },
      },
      {
        path: '/banners',
        name: 'Banners',
        component: () => import('@/views/Admin/BannersView.vue'),
        meta: { name: 'Баннеры' },
      },
      {
        path: '/orders',
        name: 'Orders',
        component: () => import('@/views/Admin/Orders/OrdersView.vue'),
        meta: { name: 'Заказы' },
      },
      {
        path: '/orders/create',
        name: 'Orders-create',
        component: () => import('@/views/Admin/Orders/OrdersCreateView.vue'),
        meta: { name: 'Создание заказа' },
      },
      {
        path: '/orders/update/:order_id',
        name: 'Orders-update',
        component: () => import('@/views/Admin/Orders/OrdersUpdateView.vue'),
        meta: { name: 'Изменение заказа' },
      },
      {
        path: '/notes',
        name: 'Notes',
        component: () => import('@/views/Admin/Notes/NotesView.vue'),
        meta: { name: 'Записи' },
      },
      {
        path: '/notes/create',
        name: 'Notes-create',
        component: () => import('@/views/Admin/Notes/NotesCreateView.vue'),
        meta: { name: 'Создать запись' },
      },
      {
        path: '/notes/update/:order_id',
        name: 'Notes-update',
        component: () => import('@/views/Admin/Notes/NotesUpdateView.vue'),
        meta: { name: 'Изменить запись' },
      },
      {
        path: '/users',
        name: 'Clients',
        component: () => import('@/views/Admin/ClientsView.vue'),
        meta: { name: 'Клиенты' },
      },
      {
        path: '/catalog',
        name: 'Catalog',
        redirect: { name: 'Catalog-main' },
        children: [...catalogRouteList],
      },
      {
        path: '/support',
        name: 'Support',
        component: () => import('@/views/Admin/SupportView.vue'),
        meta: { name: 'Поддержка' },
      },
      {
        path: '/app',
        name: 'App',
        component: () => import('@/views/Admin/AppSettingsView.vue'),
        meta: { name: 'Настройки приложения' },
      },
      {
        path: '/analytics',
        name: 'Analytics',
        component: () => import('@/views/Admin/AnalyticsView.vue'),
        meta: { name: 'Аналитика' },
      },
      {
        path: '/manage',
        name: 'Manage',
        redirect: { name: 'Settings' },
        meta: { name: 'Управление' },
        children: [
          {
            path: '/cities',
            name: 'Cities',
            component: () => import('@/views/Admin/CitiesView.vue'),
            meta: { name: 'Города' },
          },
          {
            path: '/branches',
            name: 'Branches',
            component: () => import('@/views/Admin/BranchesView.vue'),
            meta: { name: 'Филиалы' },
          },
          {
            path: '/settings',
            name: 'Settings',
            component: () => import('@/views/Admin/SettingsView.vue'),
            meta: { name: 'Настройки' },
          },
        ],
      },
    ],
  },
]

//store
export default function () {
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  })

  router.beforeEach(async (to, from, next) => {
    return next()

    // const authRequired = to.meta.auth
    // const superUserRequired = to.meta.superUser
    // const user = await store.dispatch('getCurrentUser')
    //
    // if (!authRequired) {
    //   return next()
    // }
    //
    // if (['Login'].includes(to.name) && user) {
    //   return next({ name: 'admin' })
    // }
    //
    // if (!user) {
    //   return next({ name: 'Login' })
    // }
    //
    // if (!superUserRequired) {
    //   return next()
    // } else {
    //   if (user.is_superuser) {
    //     return next()
    //   } else {
    //     return next({ name: from.name })
    //   }
    // }
  })

  const DEFAULT_TITLE = 'Axas store'

  router.afterEach(async (to) => {
    document.title = to.meta.name || DEFAULT_TITLE
  })

  return router
}
