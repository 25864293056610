export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Authorization/LoginView.vue'),
    meta: { name: 'Вход' },
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('@/views/Authorization/ResetPassword.vue'),
    meta: { name: 'Сброс пароля' },
  },
]
